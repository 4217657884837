import React from 'react'
import qs from 'query-string'
// eslint-disable-next-line
import { useLocation } from '@reach/router'
import { useQuery } from 'graphql-hooks'
import {
  ContainerFixed, Box, Text, Loader,
} from '../components/ui'
import { MetaTagsMustHave } from '../components/seo'
import client from '../graphqlClient'

const CONTENT_NODE_QUERY = `
  query GetContentNode($id: ID!) {
    contentNode(
      id: $id
      idType: DATABASE_ID
      asPreview: false
    ) {
      ... on Post {
        revisions(last: 1, before: null) {
          nodes {
            title
            meta: formattedDate
            content
            author {
              node {
                name
              }
            }
            tags {
              nodes {
                uri
                name
                count
              }
            }
            featuredImage {
              node {
                src: sourceUrl(size: LARGE)
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`

const PreviewPage = () => {
  const location = useLocation()
  const { postId: id, nonce } = qs.parse(location.search)

  client.setHeader('X-WP-Nonce', nonce)

  const { error, data } = useQuery(CONTENT_NODE_QUERY, {
    client,
    variables: { id, nonce },
  })

  let innerNode = (
    <Box textAlign="center">
      <Loader />
    </Box>
  )

  if (error) {
    innerNode = <Text>Nincs ilyen post.</Text>
  }

  const revisions = data?.contentNode?.revisions?.nodes ?? []
  const post = revisions[0]

  if (post) {
    innerNode = <Text>found post</Text>
  }

  return (
    <ContainerFixed
      p={0}
      maxWidth={1000}
    >
      <MetaTagsMustHave
        title="Preview"
      />
      {innerNode}
    </ContainerFixed>
  )
}

export default PreviewPage
